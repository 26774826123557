<template>
  <v-container>
    <v-row>
      <v-col>
        <v-autocomplete
          label="Periodo"
          item-text="nombre"
          item-value="id"
          :items="periodos"
          v-model="periodoId"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete
          label="Grupos"
          item-text="nombre"
          item-value="id"
          :items="grupos"
          v-model="grupoId"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="secondary" @click="generar">Generar boletines</v-btn>
        <v-btn color="secondary" @click="consultar">Consultar boletines</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="mostarTabla">
      <v-col>
        <v-data-table
          dense
          :headers="headers"
          :items="boletines"
          item-key="id"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                
                class="ma-2"
                outlined
                @click="descargarTodo"
              >
                Descarga completa
                <v-icon right dark> mdi-folder-zip </v-icon>
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:[`item.descargar`]="{ item }">
            <v-btn
              x-small
              icon
              color="secondary"
              @click="descargarBoletin(item.id)"
            >
              <v-icon dark> mdi-download </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import axios from 'axios';
export default {
  data: () => ({
    periodoId: 0,
    grupoId: 0,
    periodos: [],
    grupos: [],
    fechaActual: new Date().toISOString().substr(0, 10),
    boletines: [],
    headers: [
      {
        text: "Nombre Estudiante",
        align: "start",
        value: "nombreEstudiante",
      },
      { text: "Promedio", value: "promedioGeneral" },
      { text: "Puesto", value: "puesto" },
      {
        text: "Descargar",
        value: "descargar",
        align: "center",
        sortable: false,
      },
    ],
  }),
  created() {
    this.initialize();
  },
  computed: {
    mostarTabla() {
      return this.boletines.length > 0;
    },
    urlTodo() {
      return `api/Boletines/periodo/${this.periodoId}/grupo/${this.grupoId}`;
    },
  },
  methods: {
    ...mapActions([
      "GetPeriodos",
      "GetGrupos",
      "RegistrarBoletines",
      "DescargarTodoBoletines",
      "ListarBoletines"
    ]),
    initialize() {
      this.GetPeriodos().then((x) => (this.periodos = x.data));
    },
    generar() {
      let datos = {};
      datos.periodoId = this.periodoId;
      datos.grupoId = this.grupoId;
      this.RegistrarBoletines(datos).then((x) => {
        this.boletines = x.data;
      });
    },
    descargarBoletin(id) {
      axios
        .get(`api/Boletines/${id}`, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], {
            type:
              "application/pdf",
          });
          let nombreArchivo = response.headers["content-disposition"]
            .split(";")[1]
            .replace('filename="', "");
          nombreArchivo = nombreArchivo
            .slice(0, nombreArchivo.length - 1)
            .replaceAll(" ", "");
          nombreArchivo = nombreArchivo.replace("-", "_");

          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = nombreArchivo;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    consultar(){
      let datos = {};
      datos.periodo = this.periodoId;
      datos.grupo = this.grupoId;
      this.ListarBoletines(datos).then((x) => {
        this.boletines = x.data;
      });
    },
    descargarTodo() {



      axios.get(`api/Boletines/periodo/${this.periodoId}/grupo/${this.grupoId}`, {responseType: "blob"}).then((response)=>{
        debugger
        const blob = new Blob([response.data], 
              {type:'application/zip'}
          );
          let nombreArchivo = response.headers["content-disposition"]
            .split(";")[1]
            .replace('filename=', "");
          nombreArchivo = nombreArchivo
            .replaceAll(" ", "");
          nombreArchivo = nombreArchivo.replace("-", "_");

          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = nombreArchivo;
          link.click();
          URL.revokeObjectURL(link.href);
      });
    },
  },
  watch: {
    periodoId(nuevoValor) {
      if (nuevoValor > 0) {
        this.GetGrupos().then(
          (x) => (this.grupos = x.data)
        );
      } else {
        this.grupos = [];
      }
    },
  },
};
</script>