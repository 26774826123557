<template>
    <GenerarBoletines></GenerarBoletines>
</template>
<script>
import GenerarBoletines from "@/components/GenerarBoletines.vue"
export default {
    components:{
        GenerarBoletines
    }
}
</script>